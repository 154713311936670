function is_touch_enabled() {
	return ( 'ontouchstart' in window ) ||
		( navigator.maxTouchPoints > 0 ) ||
		( navigator.msMaxTouchPoints > 0 );
}

// Main Menu Button - Expand/open/close the main menu
document.getElementById('btn-icon-menu').addEventListener("click", (event) => {
    let header = document.getElementById('header');
    let menu = document.getElementById('menu-container');
    header.classList.toggle("menu-open");
    menu.classList.toggle("open");
    // menuButton.classList.toggle("open");
});


// Header - Solid background style on scroll
document.addEventListener('scroll', ()=> {
    let distance = window.scrollY;
    let header = document.getElementById('header');
    distance > 500 ? header.classList.add('solid') : header.classList.remove('solid');
});

const container = document.querySelector('.horizontal-scroll-container');
const content = document.querySelector('.info-cards-section');
const reviewsContainer = document.querySelector('.reviews-container');

container?.addEventListener('mousemove', (e) => {
    const mouseX = e.clientX;
    const containerWidth = container.clientWidth;
    const contentWidth = content.scrollWidth;
    const reviewsContainerWidth = reviewsContainer.clientWidth;

    const scrollX = (mouseX / containerWidth) * (contentWidth - containerWidth);

    content.style.transform = `translateX(-${scrollX}px)`;
});

if(document.getElementsByClassName('rellax').length > 0) {
    var rellax = new Rellax('.rellax');
}


document.addEventListener("DOMContentLoaded", () => {
    const reviewRows = document.querySelectorAll(".review-row");

    window.addEventListener("scroll", () => {
        reviewRows.forEach((row, index) => {
            const scrollPosition = window.scrollY;
            let offset;

            if (window.innerWidth >= 768) {
                offset = index % 2 === 0 ? scrollPosition * 0.03 : -scrollPosition * 0.02;
            } else {
                // Small screens (e.g., mobile)
                offset = index % 2 === 0 ? scrollPosition * 0.005 : -scrollPosition * 0.005;
            }

            row.style.transform = `translateX(${offset}px)`;
        });
    });


    const couponPopup = document.getElementById("coupon-popup");
    if(couponPopup) {

        // Check if the coupon popup should be shown
        const shouldShowPopup = sessionStorage.getItem("showCouponPopup") !== "false";
        if (shouldShowPopup) {
            // Show the coupon popup after 5 seconds
            setTimeout(() => {
                couponPopup.classList.add("show");
                couponPopup.querySelector(".coupon-inner").classList.add("show");
            }, 5000);
        }

        // Close the coupon popup and store the state in sessionStorage
        const closePopupButton = document.getElementById("btn-close-coupon-popup");
        const openCoupon = document.getElementById("btn-open-coupon");
        closePopupButton.addEventListener("click", () => {
            couponPopup.classList.remove("show");
            couponPopup.querySelector(".coupon-inner").classList.remove("show");
            sessionStorage.setItem("showCouponPopup", "false");
        });

        // Close the coupon popup when clicking outside of it
        window.addEventListener("click", (event) => {
            if (event.target === couponPopup) {
                couponPopup.classList.remove("show");
                couponPopup.querySelector(".coupon-inner").classList.remove("show");
                sessionStorage.setItem("showCouponPopup", "false");
            }
        });
        openCoupon.addEventListener("click", (event) => {
            couponPopup.classList.remove("show");
            couponPopup.querySelector(".coupon-inner").classList.remove("show");
            sessionStorage.setItem("showCouponPopup", "false");
            window.open(openCoupon.dataset.link, '_blank');
        });
    }
        
});



