if(document.getElementsByClassName('product-carousel').length > 0 && typeof Splide !== 'undefined') {

    var productSplide = new Splide('.product-carousel', {
        type: 'loop',
        mediaQuery: 'min',
        arrows: false,
        pagination: true,
        updateOnMove: true,
        perPage: 2,
        perMove: 1,
        lazyLoad: true,
        autoWidth:true,
        autoHeight:true,
        focus: 'center',
        breakpoints: {
            768: {
                perPage: 2,
                pagination: false,
            },
            1200: {
                perPage: 2,
            },
        }
    });
    
    productSplide.mount();



    var productCarousel = document.querySelector('.product-carousel');
    var cursor = document.querySelector('.cursor');
    
    productCarousel.addEventListener('mouseover', (e) => {
        cursor.style.display = 'flex';

        productCarousel.addEventListener('mousemove', function(e){
          var x = e.clientX;
          var y = e.clientY;
          cursor.style.transform = `translate3d(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%), 0)`;
          if(e.clientX < (window.innerWidth / 2)) {
            cursor.classList.add('left');
          } else {
            cursor.classList.remove('left');
          }

          if(e.target.classList.contains('link-product')) {
            cursor.classList.add('btn-hover');
          } else {
            cursor.classList.remove('btn-hover');
          }
        });
        
    });

    productCarousel.addEventListener('mouseleave', () => {
        cursor.style.display = 'none';
    });
    

    productCarousel.addEventListener('click', (e) => {
        var cursor = document.querySelector('.cursor');

        if(!e.target.classList.contains('link-product')) {
            if (cursor.classList.contains('left')) {
                productSplide.go( '<' );
            } else {
                productSplide.go( '>' );
            }    
        }
    });

}